
import './App.css';
import {Box,Typography, Paper,
  Grid,
  CardMedia,Button} from '@mui/material'
import SignupUpdated from './components/signup-updated'
import Basic from './components/form-steps/Basic';
import GMBasic from './components/form-steps-gm/Basic'
import LoginUpdated from './components/Login-updated'
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
// import lost_conn from '../public/images/lost_conn.webp'
// import BasicSales from './components/form-steps-Sales/Basic';
// import DynamicBackground from './components/Dynamicbg';
// import bf from './images/'
import { alpha, styled } from "@mui/material/styles";
import {
  Routes,
  Route,
  Navigate,
  useParams,

} from "react-router-dom";
import { useEffect, useState } from 'react';
import { useNetworkCheck } from './network-context';
// import IMI_data from './data/imi';
import Swal from 'sweetalert2'
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
// import SP_Jain_data from './data/spJain';
// import BasicDS from './components/form-steps-Sales/Basic';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2A7BCC" : "#2A7BCC",
  },
}));

function App() {
  const {isOnline} = useNetworkCheck();
  const [category,setCategory]=useState("")
  const [program,setProgram]=useState("")
  const [categoryValue,setcatValue]=useState("")
  const [email,setEmail]=useState("")
  const [alertShown, setAlertShown] = useState(false);
  useEffect(() => {
    const url = new URL(window.location.href);
    const catValue = url.searchParams.get('cat');
    const id= atob(catValue)
    const match = id.match(/&(\d+)/);
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const extractedEmails = id.match(emailRegex);
   if(extractedEmails){
    setEmail(extractedEmails[0])
   }
    
    if(match&&match[1]=="13"){
      setCategory(13)
      setProgram("General Management")
      setcatValue(catValue)
    }
    else if(match&&match[1]=="21"){
      setCategory(21)
      setProgram("Strategy & Leadership")
      setcatValue(catValue)
    }
    else if(match&&match[1]=="9"){
      setCategory(9)
      setProgram("Product Management")
      setcatValue(catValue)
    }
   
   
  }, []); 
  const showNetworkAlert = () => {
    Swal.fire({
      title: 'Network Connection Lost',
      text: 'Please check your network connection and try again.',
      icon: 'warning',
      confirmButtonText: 'OK'
    });
    // setAlertShown(true);
  };
  return (
    <div  className="App">
      {isOnline?(
      <Routes>
      {/* <Route path='/' element={<Signup />} /> */}
      {/* <Route path='/' element={<Navigate to="/spjain" replace/>} /> */}
      <Route path='/' element={<SignupUpdated category={category} program={program} catValue={categoryValue} email={email} />} />
      {/* <Route path='/login' element={<Login />} /> */}
      <Route path='/login' element={<LoginUpdated catValue={categoryValue} />} />
      <Route path='/PMform' element={<Basic catValue={categoryValue} />} /> 
      <Route path='/form' element={<GMBasic catValue={categoryValue} />} /> 
      </Routes>
      ):(
      //  <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      //  {showNetworkAlert()}
      
      //  </Box>
      <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100vh"}}>
      <Paper sx={{pt:3,pb:3,pl:6,pr:6,borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",width:{lg:"auto",md:"auto",sm:"auto",xs:"70%"}}} elevation={3}>
          <Box>
              <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              {/* <CardMedia
                component="img"
                image="../images/lost_conn.webp"
                alt="lost connection"
                sx={{
                 width:"250px",
                 height:"100px",
                 mb:2,
                  // height:"100%",382px
                  display: { xs: "block", lg: "block",sm:"block",md:"block" },
                }}
              /> */}
              <SignalWifiConnectedNoInternet4Icon sx={{fontSize:"50px",color:"#1976d2"}}/>
              
              </Box>
              <Typography sx={{fontWeight:"500",fontSize:"22px",textAlign:"center",mb:2}}>
          Lost Connection
      </Typography>
          <Typography sx={{fontWeight:"400",fontSize:"18px",textAlign:"center",mb:2,color:"gray",width:"300px"}}>
          Opps! No internet connection found. Check you Connection
      </Typography>
      <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Button onClick={()=>{window.location.reload()}}  target="_blank" sx={{textTransform:"none"}} variant="contained">Try Again</Button>
      </Box>
     
          </Box>
           
          
     
      </Paper>
      </Box>
      )}
     
    </div>
  );
}

export default App;
