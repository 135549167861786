import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { Country, State, City } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneIcon from '@mui/icons-material/Phone';
import Swal from "sweetalert2";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './Personal.css'
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 7,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 400,
    height: 19,
    padding: "10px 12px",
    boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Personal = (props) => {
  const today = new Date().toISOString().split('T')[0];
  const [openPersonal, setOpenPersonal] = useState(false);
  const [openADD, setOpenADD] = useState(false);
  const [openaddress, setOpenaddress] = useState(false);
  const [age, setAge] = useState("");
  const [name, setname] = useState(localStorage.getItem("firstname"));
  const [email, setemail] = useState(localStorage.getItem("email"));
  const [number, setnumber] = useState("");
  const [dob, setdob] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState(localStorage.getItem("city"));
  const [pincode, setPincode] = useState();
  const [address, setaddress] = useState();
  const [gender, setgender] = useState(localStorage.getItem("gender"));
  const [country_name, setCountryName] = useState();
  const [cities, setCities] = useState([]);
  const [stateCode, setStatecode] = useState();
  const [lastName, setLastname] = useState(
    (localStorage.getItem("lastname")&&
    localStorage.getItem("lastname").split(" ").length > 1)
      ? localStorage.getItem("lastname").split(" ")[1]
      : localStorage.getItem("lastname")
  );
  const [middleName, setMiddlename] = useState(
    localStorage.getItem("middlename")
  );
  const [nxt, setNxt] = useState(true);
  const [addresstwo, setAddresstwo] = useState("");
  const [backopen, setBackopen] = useState(false);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(country, stateCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        // console.log(allCities,"rrrr")
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [state, stateCode, country]);
  
  useEffect(() => {
    if (country) {
      const country_name = Country.getAllCountries().filter((val) => {
        return val.isoCode == country;
      });
      // console.log(country_name[0].name)
      setCountryName(country_name[0].name);
    }
  }, [country]);
  useEffect(() => {
    if (state) {
      const states = State.getAllStates().filter((val) => {
        return val.name == state;
      });
      // console.log(states[0].isoCode)
      setStatecode(states[0].isoCode);
    }
  }, [state]);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangeName = (event) => {
    setname(event.target.value);
  };
  const handleChangeMiddle = (event) => {
    setMiddlename(event.target.value);
  };
  const handleChangelast = (event) => {
    setLastname(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setemail(event.target.value);
  };
  const handleChangeNumber = (event) => {
    setnumber(event.target.value);
      const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number format
      setIsPhoneNumberValid(phoneRegex.test(event.target.value));
  };
  const handleChangeDob = (event) => {
    setdob(event.target.value);
    localStorage.setItem("dob",event.target.value)
  };
  const handleChangeGender = (event) => {
    setgender(event.target.value);
  };

  const handleChangePincode = (event) => {
    setPincode(event.target.value);
  };
  const handleChangeAddress = (event) => {
    setaddress(event.target.value);
  };

  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
  };
  const handleClickAdd = () => {
    setOpenADD(!openADD);
  };
  const handleClickaddress = () => {
    setOpenaddress(!openaddress);
  };
  const handleChangeAddresst = (event) => {
    setAddresstwo(event.target.value);
  };
  // useEffect(()=>{
  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/data-test.php`,
  //     data: {
  //       type: "fetch_first_step_details",
  //       user_id: localStorage.getItem("user_id"),
  //     },
  //   }).then((res)=>{
  //     if(res){
  //       setgender(res.data[0].gender)
  //       setdob(res.data[0].dob)
        
  //       localStorage.setItem("gender",res.data[0].gender)
  //       localStorage.setItem("city",res.data[0].city)
  //     }
  //   })
  // },[gender])
  // console.log(name,gender,email,number,state,city,pincode,dob,country_name,"yyy")
  useEffect(() => {
    if (
      name &&
      gender &&
      email &&
      (number) &&
      lastName&&
      // state &&
      // city &&
      // pincode &&
      dob 
      // country_name
    ) {
      setNxt(false);
    }
    else{
      setNxt(true)
    }
  }, [
    name,
    gender,
    email,
    number,
    lastName,
    // state,
    // city,
    // pincode,
    dob,
    // country_name,
  ]);

  const handleNext = () => {
    if(number.toString().slice(0,2)=='91'){
      if(number.toString().length==12){
        localStorage.setItem("mobile",number)
        LoaderOpen();
        if (
          name &&
          gender &&
          email &&
          number &&
          
          dob 
          
        ) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/insertBasicDetails`,
            data: {
              type: "insert_basic_details",
              user_id: parseInt(localStorage.getItem("user_id")),
              firstname: name,
              lastname: middleName?middleName + " " + lastName:lastName,
              email: email,
              mobile: parseInt(number),
              gender: gender,
              dob: dob,
              university:localStorage.getItem("university")
            },
          }).then(function (response) {
            LoaderClose();
            if (response.data.statusCode == 200) {
              props.handleNext();
              localStorage.setItem("currentStep", response.data.data.current_step_count);
              localStorage.setItem("currentStepStatus", response.data.data.current_step_status);
            }
            else{
              LoaderClose()
              Swal.fire({
                title: "Oops!",
                text: props.elseErrormessage,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          }).catch((err) => {
            LoaderClose();
            Swal.fire({
              title: "Oops!",
              text: props.catchErrorMessage,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
        }
        else{
          toast.error("Please enter all the required fields", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
      else{
        toast.error("Please enter a valid 10 digit mobile number", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
    else{
      LoaderOpen();
      if (
        name &&
        gender &&
        email &&
        number &&
        
        dob 
        
      ) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/insertBasicDetails`,
          data: {
            type: "insert_basic_details",
            user_id: parseInt(localStorage.getItem("user_id")),
            firstname: name,
            lastname: middleName?middleName + " " + lastName:lastName,
            email: email,
            mobile: parseInt(number),
            university:localStorage.getItem("university"),
            gender: gender,
            dob: dob,
           
          },
        }).then(function (response) {
          LoaderClose();
          if (response.data.statusCode == 200) {
            props.handleNext();
            localStorage.setItem("currentStep", response.data.data.current_step_count);
            localStorage.setItem("currentStepStatus", response.data.data.current_step_status);
          }
          else{
            LoaderClose()
            Swal.fire({
              title: "Oops!",
              text: props.elseErrormessage,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }).catch((err) => {
          LoaderClose();
          Swal.fire({
            title: "Oops!",
            text: props.catchErrorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
      }
      else{
        toast.error("Please enter all the required fields", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
   
  };
  // function userd() {
  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/IMI-backend/data-test.php`,
  //     data: {
  //       type: "get_user_basic_details",
  //       user_id: localStorage.getItem("user_id"),
  //     },
  //   }).then(function (response) {
  //     // console.log(response.data,"user")
  //     // setfirstname(response.data.firstname)
  //     // setLastName(response.data.lastname)
  //     setname(response.data.firstname);

  //     localStorage.setItem("firstname", response.data.firstname);
  //     if (response.data.lastname.split(" ").length > 1) {
  //       setLastname(response.data.lastname.split(" ")[1]);
  //       localStorage.setItem("lastname", response.data.lastname.split(" ")[1]);
  //       setMiddlename(response.data.lastname.split(" ")[0]);
  //       localStorage.setItem("middlename", response.data.lastname.split("")[0]);
  //     } else {
  //       setLastname(response.data.lastname);
  //       localStorage.setItem("lastname", response.data.lastname);
  //     }
  //   });
  // }
  // useEffect(() => {
  //   userd();
  //   // setname(props.firstname)
  //   // setLastname(props.lastName)
  // }, []);
  // useEffect(() => {
  //   var firstname = localStorage.getItem("firstname");
  //   var lastname = localStorage.getItem("lastname");
  //   var email = localStorage.getItem("email");
  //   var mobile = localStorage.getItem("mobile");
  //   setname(firstname);
  //   setLastname(lastname);
  //   setemail(email);
  //   setnumber(mobile);
  //   // console.log(firstname);
  // }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Box sx={{display:{xs:"none",lg:"block",sm:"none",md:"none"}}}>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          p: 3,

          // sx={{

          width: "95%",
          borderRadius: "15px",

          overflowY: "scroll",
          height: 350,
          "&::-webkit-scrollbar": {
            width: "0.2em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px silver",
            webkitBoxShadow: "inset 0 0 6px silver",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "silver",
            outline: "1px solid silver",
          },
          // }}
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            p: 1.5,
            background: "#fff",
            backgroundColor: "rgba(255, 255, 255, .60)",
            // pl: 4,
            // pr: 4,
         
            ml:5,
            backdropFilter: "blur(5px)",
            // display: "grid",
            placeContent: "center",
            border: "1px solid #002E5C",
            width:"82%"
          }}
        >
          {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Basic Details</Typography> */}
          <Box sx={{ p: 1.5, px:5, }}>
            <Box
              sx={{ mb: 1 }}
              //   onClick={handleClickPersonal}
            >
              {/* {openPersonal ? <ExpandLess sx={{color:"#fff"}} /> : <ExpandMore sx={{color:"#fff"}} />} */}
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Personal Details
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  First Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeName}
                  value={name}
                  sx={{ width: 480 }}
                  size="small"
                  helperText={name ? "" : "This filed is required"}
                  onKeyPress={(e) => {
                    if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
                {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Middle Name
                </Typography>
                <BootstrapInput
                  onChange={handleChangeMiddle}
                  value={middleName}
                  // sx={{ width: 480 }}
                  size="small"
                  helperText={name ? "" : "This filed is required"}
                  onKeyPress={(e) => {
                    if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
                {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Last Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangelast}
                  value={lastName}
                  sx={{ width: 480 }}
                  size="small"
                  helperText={name ? "" : "This filed is required"}
                  onKeyPress={(e) => {
                    if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
                {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Email ID <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type="email"
                  sx={{ background: "#d9d9d9", borderRadius: "5px",width:425 }}
                  size="small"
                  value={email}
                 
                  inputProps={{ readOnly: true }}
                  onChange={handleChangeEmail}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <TextField
                  type="number"
                  error={!isPhoneNumberValid}
            helperText={!isPhoneNumberValid ? 'Invalid phone number format' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PhoneIcon color={!isPhoneNumberValid ? 'error' : 'action'} />
                </InputAdornment>
              ),
            }}
                  sx={{ width: 425,background:"#fff",borderRadius:"5px" }}
                  size="small"
                  value={number}
                  onChange={handleChangeNumber}
                /> */}
                <PhoneInput
          
          country={'in'}
          // defaultCountry=""
         size="small"
        
  placeholder="Enter phone number"
  value={number}
  onChange={setnumber}
 
  />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Gender <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  {/* <InputLabel id="demo-simple-select-label">Gender</InputLabel> */}
                  <Select
                    size="small"
                    sx={{ width:423, background: "#fff",textAlign:"left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    // displayEmpty
                    // defaultValue={"male"}
                    label="Gender"
                    onChange={handleChangeGender}
                  >
                    {/* <MenuItem value={""}>{gender}</MenuItem> */}
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Date of Birth <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  type="date"
                  sx={{ width: 450 }}
                  value={dob}
                  size="small"
                  inputProps={{ max: today }}
                  onChange={handleChangeDob}
                />
              </Box>
            </Box>

           
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <Button
              sx={{
                background: "#002E5C",
                textTransform:"none",
                color: "#fff",
                "&:hover": { background: "#002E5C", color: "#fff" },
              }}
              variant="contained"
              size="small"
              width="150"
              height="80"
              onClick={handleNext}
              disabled={nxt}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      </Box>

{/* mobile version */}
      <Box sx={{display:{xs:"block",lg:"none",sm:"block",md:"block"}}} >
      <Box
        sx={{
          // display: "grid",
          // placeItems: "center",
          p: 3,
          mt:5,
          // sx={{

          width: "85%",
          borderRadius: "15px",

          overflowY: "scroll",
          height: {xs:500,sm:650,md:800},
          "&::-webkit-scrollbar": {
            width: "0.2em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px silver",
            webkitBoxShadow: "inset 0 0 6px silver",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "silver",
            outline: "1px solid silver",
          },
          // }}
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            p: 1.5,
            background: "#fff",
            backgroundColor: "rgba(255, 255, 255, .60)",
            pl: 4,
            pr: 4,
            backdropFilter: "blur(5px)",
            display: "grid",
            placeContent: "center",
            border: "1px solid #002E5C",
          }}
        >
          {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Basic Details</Typography> */}
          <Box sx={{ p: 1.5 }}>
            <Box
              sx={{ mb: 1 }}
              //   onClick={handleClickPersonal}
            >
              {/* {openPersonal ? <ExpandLess sx={{color:"#fff"}} /> : <ExpandMore sx={{color:"#fff"}} />} */}
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Personal Details
              </Typography>
            </Box>

            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  First Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeName}
                  value={name}
                  sx={{ width: {xs:220,sm:430,md:450},mb:{xs:0,sm:2,md:2} }}
                  size="small"
                  helperText={name ? "" : "This filed is required"}
                  onKeyPress={(e) => {
                    if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
                {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Middle Name
                </Typography>
                <BootstrapInput
                  onChange={handleChangeMiddle}
                  value={middleName}
                  sx={{ width: {xs:220,sm:430,md:450},mb:{xs:0,sm:2,md:2} }}
                  size="small"
                  helperText={name ? "" : "This filed is required"}
                  onKeyPress={(e) => {
                    if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
                {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
              </Box>
            </Box>
            <Box
              sx={{
                // display: "flex",
                
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Last Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangelast}
                  value={lastName}
                  sx={{ width: {xs:220,sm:430,md:450},mb:{xs:0,sm:2,md:2} }}
                  size="small"
                  helperText={name ? "" : "This filed is required"}
                  onKeyPress={(e) => {
                    if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
                {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    
                    textAlign: "left",
                  }}
                >
                  Email ID <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type="email"
                  sx={{ background: "#d9d9d9", borderRadius: "5px",width:{xs:220,sm:430,md:450},mb:{xs:0,sm:2,md:2} }}
                  size="small"
                  value={email}
                 
                  inputProps={{ readOnly: true }}
                  onChange={handleChangeEmail}
                />
              </Box>
            </Box>

            <Box
              sx={{
                // display: "flex",
            
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <TextField
                  type="number"
                  
                  error={!isPhoneNumberValid}
            helperText={!isPhoneNumberValid ? 'Invalid phone number format' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PhoneIcon color={!isPhoneNumberValid ? 'error' : 'action'} />
                </InputAdornment>
              ),
            }}
                  sx={{ width: 220,background:"#fff",borderRadius:"5px" }}
                  size="small"
                  value={number}
                  onChange={handleChangeNumber}
                /> */}
                <PhoneInput
          
          country={'ind'}
         size="small"
        
  placeholder="Enter phone number"
  value={number}
  onChange={setnumber}
 
  />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Gender <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl>
                  {/* <InputLabel id="demo-simple-select-label">Gender</InputLabel> */}
                  <Select
                    size="small"
                    sx={{ width:{xs:220,sm:430,md:450},mb:{xs:0,sm:2,md:2}, background: "#fff",textAlign:"left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    label="Gender"
                    onChange={handleChangeGender}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Date of Birth <span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  type="date"
                  sx={{ width: {xs:220,sm:430,md:450},mb:{xs:0,sm:2,md:2} }}
                  value={dob}
                  inputProps={{ max: today }}
                  size="small"
                  onChange={handleChangeDob}
                />
              </Box>
            </Box>

            
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <Button
              sx={{
                background: "#002E5C",
                textTransform:"none",
                color: "#fff",
                "&:hover": { background: "#002E5C", color: "#fff" },
              }}
              variant="contained"
              size="small"
              width="150"
              height="80"
              onClick={handleNext}
              disabled={nxt}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      </Box>
     
    </>
  );
};

export default Personal;
