import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EmailIcon from '@mui/icons-material/Email';
import { Country, State, City } from "country-state-city";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 7,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 400,
    height: 19,
    padding: "10px 12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2A7BCC" : "#2A7BCC",
  },
}));

const Education = (props) => {
  const [openPersonal, setOpenPersonal] = useState(false);
  const [openADD, setOpenADD] = useState(false);
  const [openaddress, setOpenaddress] = useState(false);
  const [bachelorDegree, setBachelorDegree] = useState("");
  const [bachelorDegreeOther, setBachelorDegreeother] = useState("");
  const [collegeName, setCollegename] = useState("");
  const [yearOfCompletion, setCompletionyear] = useState("");
  const [cgpa, setCgpa] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [companyemail, setCompanyemail] = useState();
  const [previuosCompany, setPreviouscompany] = useState();
  const [bachelorDocuments, setBachelorDcument] = useState("");
  const [experienceDocuments, setexperienceDcument] = useState("");
  const [nxt, setNxt] = useState(true);
  const [check,setChecked]=useState('')
  const [check2,setChecked2]=useState('')
  const [choice, setChoice] = useState("");
  const [backopen, setBackopen] = useState(false);
  const [ispincodeValid, setIspincodeValid] = useState(true);
  const [qualification, setqualification] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [Total_exp, setExp] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryOther, setIndustryOther] = useState("");
  const [designation, setDesignation] = useState("");
  const [previndustry, setprevIndustry] = useState("");
  const [previndustryOther, setprevIndustryOther] = useState("");
  const [error, setError] = useState(false);
  const [validCmpname, setCmpname] = useState(false);
  

  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [address, setaddress] = useState();
  const [addresstwo, setAddresstwo] = useState("");
  const [country_name, setCountryName] = useState();
  const [cities, setCities] = useState([]);
  const [stateCode, setStatecode] = useState();
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };

  const option=[{
    value:"Yes",label:"Yes"
  },
{value:"No",label:"No"}
]

const handleChangePincode = (event) => {
  // setPincode(event.target.value);
  setPincode(event.target.value);
    setIspincodeValid(/^\d*\.?\d*$/.test(event.target.value));
};
const handleChangeAddress = (event) => {
  setaddress(event.target.value);
};
const handleChangeAddresst = (event) => {
  setAddresstwo(event.target.value);
};
const handleChangeCountry = (event) => {
  setCountry(event.target.value);
  setState('');
  setCity('')
};
const handleChangeState = (event) => {
  setState(event.target.value);
  setCity('')
};
const handleChangeCity = (event) => {
  setCity(event.target.value);
};
useEffect(() => {
  const getCities = async () => {
    try {
      const result = await City.getCitiesOfState(country, stateCode);
      let allCities = [];
      allCities = result?.map(({ name }) => ({
        name,
      }));
      // console.log(allCities,"rrrr")
      setCities(allCities);
    } catch (error) {
      setCities([]);
    }
  };

  getCities();
}, [state, stateCode, country]);
// console.log(gender,"uuuu")
useEffect(() => {
  if (country) {
    const country_name = Country.getAllCountries().filter((val) => {
      return val.isoCode == country;
    });
    // console.log(country_name[0].name)
    setCountryName(country_name[0].name);
  }
}, [country]);
useEffect(() => {
  if (state) {
    const states = State.getAllStates().filter((val) => {
      return val.name == state;
    });
    // console.log(states[0].isoCode)
    setStatecode(states[0].isoCode);
  }
}, [state]);

  const handleChangeChoice = (val) => {
    setChoice(val);
    setCompanyName('')
    setCompanyemail('')
    setPreviouscompany('')
    setExp('')
    setIndustry('')
    setDesignation('')
    setIndustryOther('')
    setprevIndustry('')
    setprevIndustryOther('')
    setChecked(false)
    setChecked2(false)
  };
  const handleChangeDegree = (event) => {
    setBachelorDegree(event.target.value);
  };
  const handleChangeDegreeOther = (event) => {
    setBachelorDegreeother(event.target.value);
  };
  // const handleChangeCollegeName = (event) => {
  //   setCollegename(event.target.value);
  // };
  const handleChangeCollegeName = (event) => {
    const regex = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
     if (
      regex.test(event.target.value)
    ) {
      setError(true);
    } else {
      setError(false);
     
    }
    setCollegename(event.target.value)
    
  };
  const handleChangeYear = (event) => {
    setCompletionyear(event.target.value);
  };
  const handleChangeCgpa = (event) => {
    setCgpa(event.target.value);
  };
  // const handleChangeCpmanyName = (event) => {
  //   setCompanyName(event.target.value);
  // };
  const handleChangeCpmanyName = (event) => {
    const regex = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
    if (
     regex.test(event.target.value)
   ) {
     setCmpname(true);
   } else {
    setCmpname(false);
    
   }
    setCompanyName(event.target.value);
  };
  const handleChangeCompanyemail = (event) => {
    setCompanyemail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(event.target.value));
  };
  const handleChangePreviousCompany = (event) => {
    setPreviouscompany(event.target.value);
  };
  const handleChangeDesignation = (event) => {
    setDesignation(event.target.value);
  };
  const handleChangePrevIndustry=(event)=>{
    setprevIndustry(event.target.value)
  }
  const handleChangePrevIndustryOther=(event)=>{
    setprevIndustryOther(event.target.value)
  }
  const handleChangeBachelorDocuments = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 5000000) {
      setBachelorDcument(selectedFile);
    } else {
      setBachelorDcument(null);
      alert("Please select a file of size not more than 5MB");
    }
    // setBachelorDcument(event.target.files[0]);
  };
  const handleChangeTotalExp = (event) => {
    setExp(event.target.value);
  };
  const handleChangeExperinceDocuments = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 5000000) {
      setexperienceDcument(selectedFile);
    } else {
      setexperienceDcument(null);
      alert("Please select a file of size not more than 5MB");
    }
    // setexperienceDcument(event.target.files[0]);
  };
  const handleChangequalification = (event) => {
    setqualification(event.target.value);
  };
  const handleChangeIndustry=(event)=>{
    setIndustry(event.target.value)
  }
  const handleChangeIndustryOther=(event)=>{
    setIndustryOther(event.target.value)
  }

  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
  };
  const handleClickAdd = () => {
    setOpenADD(!openADD);
  };
  const handleClickaddress = () => {
    setOpenaddress(!openaddress);
  };
  const handleChange2=( event)=>{
    setChecked2(event.target.checked);
  }
  // console.log(
  //   bachelorDegree,
  //   collegeName,
  //   yearOfCompletion,
  //   cgpa,
  //   companyname,
  //   companyemail,
  //   previuosCompany,
  //   bachelorDocuments,
  //   experienceDocuments
  // );
  // if(props){
  useEffect(() => {
    if(country_name&&state&&city&&(ispincodeValid?pincode:false)&&address&&(localStorage.getItem("working")=="no"?(experienceDocuments&&bachelorDocuments):(experienceDocuments||bachelorDocuments))){
      setNxt(false);
    }
    else{
      setNxt(true);
    }
  }, [
    country_name,state,city,pincode,address,
    bachelorDocuments,
    experienceDocuments,
  ]);
  
  const InsertintoSheet=(data)=>{
    const originalTimestamp = new Date(data.dob);
    const formattedDate = originalTimestamp.toISOString().slice(0, 10);
    const formDatab = new FormData();
    // formDatab.append("Sno",sn);
    formDatab.append("Batch",data.batch);
    formDatab.append("Name",data.name);
    formDatab.append("Email",data.email);
    formDatab.append("Gender",data.gender);
    formDatab.append("Dob",formattedDate);
    formDatab.append("Phone",data.phone);
    formDatab.append("City",data.city);
    formDatab.append("Workex",data.experience);
    formDatab.append("Education",data.highest_education);
    formDatab.append("Company",data.company);
    formDatab.append("Designation",data.designation);
    formDatab.append("Industry",data.industry);
    formDatab.append("WorkexLink",data.company_doc_file_path);
    formDatab.append("Educationlink",data.degree_file_path);
    formDatab.append("LMS","Yes");
    formDatab.append("Enrollment","Yes");
  
  fetch(
 props.provisionalSheetUrl,
  {
  method: "POST",
  body: formDatab
  }
  )
  .then((res) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_URL}/api/v1/pushTogenerateXlriApplication`,
      data: {
        user_id: localStorage.getItem("user_id"),
        category_id: localStorage.getItem("category"),
        fullname:localStorage.getItem("firstname")+" "+localStorage.getItem("lastname"),
        university:"xlri",
        email:localStorage.getItem("email"),
        type:"PL"
      },

      // options
    }).then((res)=>{
      if (res.data.status == 200) {
        LoaderClose()
        // props.handleNext();
        localStorage.setItem("currentStep", res.data.current_step_count);
        localStorage.setItem("currentStepStatus", res.data.current_step_status);
        
        }
    })
    
    res.json()})
  .then((data) => {
  console.log(data);
  })
  .catch((error) => {
  console.log(error);
  });
    
    
  
  }
  const handleNext = () => {
    LoaderOpen();
    if (
      country_name&&state&&city
      // bachelorDocuments
    ) {
      var formData = new FormData();
      formData.append("education_doc", bachelorDocuments);
      // ? :/
      if(localStorage.getItem("working")=="no"){
        formData.append("work_doc", experienceDocuments)
      }
      formData.append("type", "insert_gm_doc_address_details");
      formData.append("country", country_name);
      formData.append("state", state);
      formData.append("email", localStorage.getItem("email"));
      formData.append("city", city);
      formData.append("pincode", parseInt(pincode));
      formData.append("address", address);
      formData.append("university", localStorage.getItem("university"));
      formData.append("addresstwo", addresstwo);
      formData.append("user_id", parseInt(localStorage.getItem("user_id")));
      formData.append("working",localStorage.getItem("working"));
      // formData.append("degree", bachelorDegree=="others"?bachelorDegreeOther:bachelorDegree);
      // formData.append("qualification", qualification);
      // formData.append("college_name", collegeName);
      // formData.append("completion_year", yearOfCompletion);
      // formData.append("cgpa", cgpa);
      
      // formData.append("company_name", choice=="Yes"?companyname:previuosCompany);
      // formData.append("company_email", companyemail);
      // formData.append("designation", designation);
      // choice=="Yes"?formData.append("current_industry", industry=="others"?industryOther:industry):formData.append("previous_industry", previndustry=="others"?previndustryOther:previndustry);
      // // formData.append("current_industry", cgpa);
      // formData.append("experience", Total_exp);
     
     


      axios({
        method: "post",
        url: `${process.env.REACT_APP_NODE_URL}/api/v1/fileUplaod/insertStudentDetails`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
       
      }).then(function (response) {
        if (response.status == 200) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/fetchSheetData`,
            data: {
              type: "fetch_sheet_data",
              user_id: parseInt(localStorage.getItem("user_id")),
              category:localStorage.getItem("category"),
              university:localStorage.getItem("university"),
              email:localStorage.getItem("email"),
            },
          }).then((res)=>{
           if(res.data.statusCode==200){
            InsertintoSheet(res.data.data[0])
           }
          else{
            LoaderClose()
        Swal.fire({
          title: "Oops!",
          text: props.elseErrormessage,
          icon: "error",
          confirmButtonText: "OK",
        });
          }
          })
        }
        else{
          LoaderClose()
          Swal.fire({
            title: "Oops!",
            text: props.elseErrormessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        LoaderClose()
        Swal.fire({
          title: "Oops!",
          text: props.catchErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    }
    else{
      toast.error("Please Enter all the required feilds", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChange=( event)=>{
    setChecked(event.target.checked);
  }
  // const choice=""
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
       <Box sx={{ display: "flex" }}>
          <CircularProgress color="inherit" />
          <Typography sx={{ ml: 2 }}>
          Please wait while we process your application form.
          </Typography>
        </Box>
      </Backdrop>
      <Box sx={{ display: { xs: "none", lg: "block",sm:"none",md:"none" } }}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            p: 3,
            width: "95%",
            borderRadius: "15px",

            overflowY: "scroll",
            height: 350,
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px silver",
              webkitBoxShadow: "inset 0 0 6px silver",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "silver",
              outline: "1px solid silver",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              p: 1.5,
              background: "#fff",
              backgroundColor: "rgba(255, 255, 255, .60)",
              // pl: 4,
              // pr: 4,

              ml: 5,
              backdropFilter: "blur(5px)",
              // display: "grid",
              placeContent: "center",
              border: "1px solid #002E5C",
              width: "82%",
            }}
          >
             {localStorage.getItem("currentStepStatus")=='pending'?(
              <Box>
                 <Box sx={{ p: 1.5 }}>
            <Box
              sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
              // onClick={handleClickaddress}
            >
             
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Address Details
              </Typography>
            </Box>
          
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    // defaultValue={countryCode}
                    label="Gender"
                    onChange={handleChangeCountry}
                    
                  >
                    {Country.getAllCountries().map((val, i) => (
                      <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    label="Gender"
                    onChange={handleChangeState}
                    // renderValue={(selected) => selected.map(obj=> State[obj - 1].value).join(", ")}
                  >
                    {State.getAllStates()
                      .filter((val) => {
                        return val.countryCode == country;
                      })
                      .sort()
                      .map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>

                <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 427, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="Gender"
                    onChange={handleChangeCity}
                  >
                    {cities.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Pincode <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <BootstrapInput
                  onChange={handleChangePincode}
                  value={pincode}
                  type="number"
                  // sx={{ width: 480 }}
                  size="small"
                /> */}
                 <TextField
                    type="text"
                    error={!ispincodeValid}
                    helperText={!ispincodeValid ? 'Please enter only number' : ''}
                    
                    onChange={handleChangePincode}
                    value={pincode}
                    sx={{ width: 427, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 1<span style={{ color: "red" }}>*</span>
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddress}
                  value={address}
                  sx={{ width: 480 }}
                  size="small"
                  type="text"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 2{" "}
                </Typography>
                <BootstrapInput
                  onChange={handleChangeAddresst}
                  value={addresstwo}
                  // sx={{ width: 480 }}
                  size="small"
                  type="text"
                />
              </Box>
            </Box>
            <Box
             sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
              mb: 1,
            }}
            >
              <Box>
              <Typography
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#000",textAlign:"left" }}
            >
              Upload Education Document
              <span style={{ color: "red" }}>*<i style={{ color: "#000",fontSize:"12px" }}>(pdf format)</i></span>
                
            </Typography>
            <TextField
              inputProps={{
                accept: "application/pdf",
                // onChange: handleFileChange,
              }}
              onChange={handleChangeBachelorDocuments}
              size="small"
              sx={{ width: 427, background: "#fff", borderRadius: "4px" }}
              fullWidth
              type="file"
            ></TextField>
              </Box>
              {localStorage.getItem("working")=="no"?(
              <Box>
                 <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign:"left"
                    }}
                  >
                    Upload Experience Document
                    <span style={{ color: "red" }}>*<i style={{ color: "#000",fontSize:"12px" }}>(pdf format)</i></span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf",
                      // onChange: handleFileChange,
                    }}
                    onChange={handleChangeExperinceDocuments}
                    size="small"
                    sx={{ width: 427, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
              </Box>
            ):(
              <Box>

              </Box>
            )}
              </Box>
           
              
              {/* {choice == "" ? (
          <Box></Box>
        ) : (
          <Box>
            {choice == "Yes" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Current Company Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangeCpmanyName}
                    // value={companyname}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Company Email Id <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type="email"
                    // onChange={handleChangeCompanyemail}
                    // value={companyemail}
                    sx={{ width: 480, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Previous Company Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangePreviousCompany}
                    // value={previuosCompany}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Upload Relevant Document
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf, image/*",
                      // onChange: handleFileChange,
                    }}
                    // onChange={handleChangeExperinceDocuments}
                    size="small"
                    sx={{ width: 480, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
              </Box>
            )}
          </Box>
        )} */}

              {/* </Collapse> */}
              {/* <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
          <Button
            variant="conatained"
            size="small"
            sx={{
              color: "#fff",
              background: "#fdb714",
              "&:hover": { background: "#fdb714", color: "#fff" },
            }}
            // onClick={handleNext}
            // disabled={nxt}
          >
            Submit
          </Button>
        </Box> */}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#fff",
                mb: 1,
              }}
            >
              <i>Please make the payment below</i>
            </Typography> */}

<Button
              sx={{
                background: "#002E5C",
                textTransform:"none",
                color: "#fff",
                "&:hover": { background: "#002E5C", color: "#fff" },
              }}
              variant="contained"
              size="small"
              width="150"
              height="80"
              onClick={handleNext}
              disabled={ nxt}
            >
              Submit
            </Button>
            </Box>
              </Box>
             ):(
              <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "5px",
                  border: "1px solid #2A7BCC",
                  p: 4,
                  width: 250,
                  mt:5,
                  mb:5
                }}
              >
                <BorderLinearProgress
                  variant="determinate"
                  value={80}
                  sx={{ mb: 2 }}
                />
                <Typography sx={{ mb: 1.5 }}>
                Thank you for submitting your application and required documents! Our team is diligently verifying the information. Expect a provisional acceptance letter within 24 business hours.
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleClickChange}
                    variant="conatained"
                    size="small"
                    sx={{
                      textAlign: "center",
                      color: "#fff",
  
                      textTransform: "none",
                      background: "#2A7BCC",
                      "&:hover": { background: "#2A7BCC", color: "#fff" },
                    }}
                    // onClick={handleChangePayment}
                  >
                    Choose Another Payment Method
                  </Button>
                </Box> */}
              </Box>
            </Box>
             )}
            {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Education and Work Details</Typography> */}
           
          </Box>
        </Box>
      </Box>

      {/* mobile version */}
      <Box sx={{ display: { xs: "block", lg: "none",sm:"block",md:"block" } }}>
        <Box
          sx={{
            // display: "grid",
            // placeItems: "center",
            p: 3,
            width: "85%",
            borderRadius: "15px",
            mt:3,
            overflowY: "scroll",
            height: {xs:500,sm:700,md:800},
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px silver",
              webkitBoxShadow: "inset 0 0 6px silver",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "silver",
              outline: "1px solid silver",
            },
          }}
        >
          {/* <Box
            sx={{
              borderRadius: "8px",
              p: 1.5,
              background: "#fff",
              backgroundColor: "rgba(255, 255, 255, .60)",
              pl: 4,
              pr: 4,
              backdropFilter: "blur(5px)",
              display: "grid",
              placeContent: "center",
              border: "1px solid #002E5C",
            }}
          > */}
            {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Education and Work Details</Typography> */}
            {localStorage.getItem("currentStepStatus")=='pending'?(
              <Box
              sx={{
                borderRadius: "8px",
                p: 1.5,
                background: "#fff",
                backgroundColor: "rgba(255, 255, 255, .60)",
                pl: 4,
                pr: 4,
                backdropFilter: "blur(5px)",
                display: "grid",
                placeContent: "center",
                border: "1px solid #002E5C",
              }}
              >
                 <Box sx={{ p: 1.5 }}>
            <Box
              sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
              // onClick={handleClickaddress}
            >
             
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                Address Details
              </Typography>
            </Box>
            
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <FormControl fullWidth>
                  
                  <Select
                    size="small"
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Gender"
                    onChange={handleChangeCountry}
                  >
                    {Country.getAllCountries().map((val, i) => (
                      <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth>
                  
                  <Select
                    size="small"
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    label="Gender"
                    onChange={handleChangeState}
                  >
                    {State.getAllStates()
                      .filter((val) => {
                        return val.countryCode == country;
                      })
                      .sort()
                      .map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>

                <FormControl fullWidth>
                 
                  <Select
                    size="small"
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={city}
                    label="Gender"
                    onChange={handleChangeCity}
                  >
                    {cities.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Pincode <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <TextField
                  onChange={handleChangePincode}
                  value={pincode}
                  type="number"
                  sx={{ width: {xs:250,sm:430,md:450},background:"#fff",borderRadius:"5px" }}
                  // sx={{ width: {xs:250,sm:430,md:450} }}
                  size="small"
                /> */}
                 <TextField
                    type="text"
                    error={!ispincodeValid}
                    helperText={!ispincodeValid ? 'Please enter only number' : ''}
                    
                    onChange={handleChangePincode}
                    value={pincode}
                    sx={{ width: { xs: 250, sm: 430, md: 450 }, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
              </Box>
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 1 <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  value={address}
                  sx={{ width: {xs:250,sm:430,md:450},background:"#fff",borderRadius:"5px" }}
                  size="small"
                  type="text"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Address 2{" "}
                </Typography>
                <TextField
                  onChange={handleChangeAddresst}
                  value={addresstwo}
                  sx={{ width: {xs:250,sm:430,md:450},background:"#fff",borderRadius:"5px" }}
                  size="small"
                  type="text"
                />
              </Box>
            </Box>
            <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign:"left"
                    }}
                  >
                    Upload Education Document
                    <span style={{ color: "red" }}>*<i style={{ color: "#000",fontSize:"12px" }}>(pdf format)</i></span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf",
                      // onChange: handleFileChange,
                    }}
                    onChange={handleChangeBachelorDocuments}
                    size="small"
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
             {localStorage.getItem("working")=="no"?(
               <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign:"left"
                    }}
                  >
                    Upload Experience Document
                    <span style={{ color: "red" }}>*<i style={{ color: "#000",fontSize:"12px" }}>(pdf format)</i></span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf",
                      // onChange: handleFileChange,
                    }}
                    onChange={handleChangeExperinceDocuments}
                    size="small"
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box> 
             ):(
              <Box>

              </Box>
             )
            }
              {/* {choice == "" ? (
          <Box></Box>
        ) : (
          <Box>
            {choice == "Yes" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Current Company Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangeCpmanyName}
                    // value={companyname}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Company Email Id <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type="email"
                    // onChange={handleChangeCompanyemail}
                    // value={companyemail}
                    sx={{ width: 480, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Previous Company Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangePreviousCompany}
                    // value={previuosCompany}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Upload Relevant Document
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf, image/*",
                      // onChange: handleFileChange,
                    }}
                    // onChange={handleChangeExperinceDocuments}
                    size="small"
                    sx={{ width: 480, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
              </Box>
            )}
          </Box>
        )} */}

              {/* </Collapse> */}
              {/* <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
          <Button
            variant="conatained"
            size="small"
            sx={{
              color: "#fff",
              background: "#fdb714",
              "&:hover": { background: "#fdb714", color: "#fff" },
            }}
            // onClick={handleNext}
            // disabled={nxt}
          >
            Submit
          </Button>
        </Box> */}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#fff",
                mb: 1,
              }}
            >
              <i>Please make the payment below</i>
            </Typography> */}

              <Button
                // variant="conatained"
                // size="small"
                sx={{
                  color: "#fff",
                  mt: 1,
                  background: "#002E5C",
                  "&:hover": { background: "#002E5C", color: "#fff" },
                }}
                // onClick={handleNext}
                // disabled={nxt}
                variant="contained"
                size="small"
                width="150"
                height="80"
                onClick={handleNext}
                disabled={ nxt}
              >
                Submit
              </Button>
            </Box>
              </Box>
            ):(
              <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt:10
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "5px",
                  border: "1px solid #2A7BCC",
                  p: 3,
                  width: 250,
                  mt:3
                }}
              >
                <BorderLinearProgress
                  variant="determinate"
                  value={80}
                  sx={{ mb: 2 }}
                />
                <Typography sx={{ mb: 1.5 }}>
                Thank you for submitting your application and required documents! Our team is diligently verifying the information. Expect a provisional acceptance letter within 24 business hours.
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleClickChange}
                    variant="conatained"
                    size="small"
                    sx={{
                      textAlign: "center",
                      color: "#fff",
  
                      textTransform: "none",
                      background: "#2A7BCC",
                      "&:hover": { background: "#2A7BCC", color: "#fff" },
                    }}
                    // onClick={handleChangePayment}
                  >
                    Choose Another Payment Method
                  </Button>
                </Box> */}
              </Box>
            </Box>
            )}
           
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Education;
