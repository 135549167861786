import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Link,
  Badge,
  Checkbox,
  Tabs,
  Tab,
  Step,
  CardMedia
} from "@mui/material";

// import XLRI_LOGO from '../../images/xlri.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';




const Offer=(props)=>{
//  const InsertintoSheet=(data)=>{
 
//     const formDatab = new FormData();
//     // formDatab.append("Sno",sn);
//     formDatab.append("Batch",data.batch);
//     formDatab.append("Name",data.name);
//     formDatab.append("Email",data.email);
//     formDatab.append("Gender",data.gender);
//     formDatab.append("Dob",data.dob);
//     formDatab.append("Phone",data.phone);
//     formDatab.append("City",data.city);
//     formDatab.append("Workex",data.experience);
//     formDatab.append("Education",data.highest_education);
//     formDatab.append("Company",data.company);
//     formDatab.append("Designation",data.designation);
//     formDatab.append("Industry",data.industry);
//     formDatab.append("WorkexLink",data.company_doc_file_path);
//     formDatab.append("Educationlink",data.degree_file_path);
//     formDatab.append("LMS","Yes");
//     formDatab.append("Enrollment","Yes");
  
//   fetch(
//  props.sheetURL,
//   {
//   method: "POST",
//   body: formDatab
//   }
//   )
//   .then((res) => {
//     axios({
//       method: "post",
//       url: `${process.env.REACT_APP_BASE_URL}/payment-portal-backend/data-test.php`,
//       data: {
//         type: "update_last_step_status",
//         user_id: parseInt(localStorage.getItem("user_id")),
//         university:localStorage.getItem("university")
//       },
//     }).then((res)=>{
//       localStorage.setItem("currentStep", res.data.data.current_step_count);
//       localStorage.setItem("currentStepStatus", res.data.data.current_step_status);
//     })
    
//     res.json()})
//   .then((data) => {
//   console.log(data);
//   })
//   .catch((error) => {
//   console.log(error);
//   });
    
    
  
//   }
//    useEffect(()=>{
//     if(localStorage.getItem("currentStepStatus")=="pending"){
//       axios({
//                         method: "post",
//                         url: `${process.env.REACT_APP_BASE_URL}/payment-portal-backend/data-test.php`,
//                         data: {
//                           type: "fetch_sheet_data",
//                           user_id: parseInt(localStorage.getItem("user_id")),
//                           category_id:localStorage.getItem("category")
//                         },
//                       }).then((res)=>{
//                        if(res.data[0].status==200){
//                         InsertintoSheet(res.data[0])
//                        }})
//     }
//    },[])
    return (
        <>
      {/* desktop version */}

       <Box sx={{display: { xs: "none", lg: "flex",sm:"none",md:"none"},justifyContent:"center",alignItems:"center",mt:7}}>
       
              <Box sx={{background:"#fff",borderRadius:"5px",border:"1px solid #2A7BCC",px:3,pb:3,width:330}}>
              <Box sx={{textAlign:"center"}}>
        <CheckCircleIcon sx={{color:"#2A7BCC",fontSize:"80px",pt:1,pb:0.5}}/>
       </Box>
                <Typography sx={{textAlign:"center",fontWeight:"bold",fontSize:"20px",mb:0.5}}>Thank You!</Typography>
                <Typography sx={{textAlign:"center",fontWeight:"300",fontSize:"16px",mb:3}}>
               
                {props.text}
                </Typography>
            <Box sx={{display:"flex",justifyContent:"center",alignItem:"center"}}>
             
              
              <Button  href="https://home.accredian.com/Login" target="blank"  variant="conatained" size="small" sx={{color:"#fff",background:"#2A7BCC",textTransform:"none",textAlign:"center",width:150
          ,"&:hover":{background:"#2A7BCC",color:"#fff" }}}
          
          >
          Go To Dashboard
          </Button>
            {/* </a> */}
            
            
          
            </Box>
                
              </Box>
            </Box>
           {/* mobile version */}
           <Box sx={{display: { xs: "flex", lg: "none",sm:"flex",md:"flex"},justifyContent:"center",alignItems:"center",mt:{xs:10,sm:20,md:30 }}}>
       
              <Box sx={{background:"#fff",borderRadius:"5px",border:"1px solid #2A7BCC",px:3,pb:3,width:{xs:270,sm:370,md:450}}}>
              <Box sx={{textAlign:"center"}}>
        <CheckCircleIcon sx={{color:"#2A7BCC",fontSize:"80px",pt:1,pb:0.5}}/>
       </Box>
                <Typography sx={{textAlign:"center",fontWeight:"500",fontSize:"20px",mb:0.5}}>Thank You!</Typography>
                <Typography sx={{textAlign:"center",fontWeight:"300",fontSize:"16px",mb:3}}>
                
                {props.text}
                </Typography>
            <Box sx={{display:"flex",justifyContent:"center",alignItem:"center"}}>
             
              
            <Button  href="https://home.accredian.com/Login" target="blank"  variant="conatained" size="small" sx={{color:"#fff",background:"#2A7BCC",textTransform:"none",textAlign:"center",width:150
          ,"&:hover":{background:"#2A7BCC",color:"#fff" }}}
          
          >
          Go To Dashboard
          </Button>
            
          
            </Box>
                
              </Box>
            </Box>
      
            
        </>
    )
}
export default Offer