import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Navbar from "./Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import logo from "../images/image_10.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import axios from "axios";

const LoginUpdated = (props) => {
  let navigate = useNavigate();
  // const {id}=useParams()
  const [showPassword, setShowPassword] = useState(false);
  // const[id,setid]=useState("iimv")
  const [url, setUrl] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [backopen, setBackopen] = useState(false);
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  // const [user, setUser] = useState({ email: "", password: "" });
  const [email ,setEmail] = useState(localStorage.getItem("email"));
  const [password,setPassword] = useState("");
  // useEffect(()=>{
  //   const url = new URL(window.location.href);
  //   setid("iimv")
  //   // setid(url.hostname.split('.')[0]);
  //   import(`../data/${id}_data.js`)
  //   .then((module) => {
  //     const data = module.default;
  //     setUrl(data[0].bg)
  //   })
  //   .catch((error) => {
  //     console.error(`Error loading property file: ${error}`);
  //   });
  // // const ans=  payment_data.filter((val)=>{
  // //  return val.id==id
  // //   })
  // //   setUrl(ans[0].bg)

  // },[id])
  function userd(){
    // console.log('users function triggered');
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/getGmUserBasicDetails`,
      data: {
          type: 'get_gm_user_basic_details',
          user_id:localStorage.getItem("user_id"),
          university:"xlri",
          email:localStorage.getItem("email")
          
        }
    })
      .then(function (response) {
        if(response.data.statusCode==404){
          Swal.fire({
            title: "Warning",
            text: "The admission portal has not been set up on your account. Please get in touch with the admission counsellor.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        else{
          localStorage.setItem("currentStep", response.data.data[0].current_step_count);
          localStorage.setItem("university", "xlri");
          localStorage.setItem("currentStepStatus", response.data.data[0].current_step_status);
          localStorage.setItem("working", response.data.data[0].working);
          localStorage.setItem("firstname", response.data.data[0].firstname);
          localStorage.setItem("mobile", response.data.data[0].mobile);
          localStorage.setItem("category", response.data.data[0].category);
          if(response.data.data[0].lastname.split(" ").length>1){
            localStorage.setItem("lastname", response.data.data[0].lastname.split(" ")[1]);
            localStorage.setItem("middlename", response.data.data[0].lastname.split(" ")[0]);
          }
          else{
            localStorage.setItem("lastname", response.data.data[0].lastname);
          }
          if(response.data.data[0].category=='13' || response.data.data[0].category=='21' ){
            navigate(`/form`)
          }
          else if (response.data.data[0].category=='9'){
            navigate(`/PMform`)
          }
          else{
            // navigate(`/DTform`)
            Swal.fire({
              title: "Warning",
              text: "The admission portal has not been set up on your account. Please get in touch with the admission counsellor.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
       
      }).catch((err)=>{
        toast.error("Apologies for any inconvenience. Our system is experiencing high demand, causing delays. Please retry later. Your patience is appreciated.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
   
    
  
  }
  const handleChange = (e) => {
    setEmail(e.target.value);
  
  };
  const handleChangepassword = (e) => {
 
    setPassword(e.target.value);
  };

  const removeSpace=(pass)=>{
    const originalString = pass;
const reducedString = originalString.replace(/\s+/g, '');

return reducedString; // Output: 'ABCD'
  }

  // console.log(password,removeSpace(password),password.length,removeSpace(password).length,"pass")
  const submitForm = (e) => {
    if (email && password) {
      LoaderOpen();
      e.preventDefault();
      axios({
        method: "post",
        url: `${process.env.REACT_APP_NODE_URL}/api/v1/login/`,
        data: {
          type: "login_up",
          email: email,
          password: removeSpace(password),
        },
      }).then(function (response) {
        LoaderClose();
        
        if (response.data.statusCode == 200) {
         
          localStorage.setItem("user_id", response.data.data.user_id);
          localStorage.setItem("email", response.data.data.email);
          
          userd();
          
          
         
        } else if (response.data.statusCode == 400) {
          toast.error("Please check the email or sign up to create a new account.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } 
        else if (response.data.statusCode == 401) {
          toast.error("The password entered is incorrect. Please double-check your password or use the 'Lost Your Password' option to reset it.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }).catch((err)=>{
        LoaderClose();
          toast.error("Apologies for any inconvenience. Our system is experiencing high demand, causing delays. Please retry later. Your patience is appreciated.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      })
    } else {
      toast.error("Please enter all the required fields", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <>
      <Navbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Box sx={{display:{xs:"none",lg:"block",sm:"block",md:"block"},
    // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${url})`,
    //  backgroundSize: "cover",
    //  backgroundAttachment:"fixed",
    //  height:{lg:"650px",sm:"93vh",md:"95vh"}
    }}>
      <Box
sx={{ pt:{lg:15,sm:25,md:45} }}
      >
        {/* <Grid container>
          <Grid item lg={9}>
            <Box > */}
              <Container fixed >
                <Box>
                  <Box>
                    <Grid container spacing={4} justifyContent="center">
                      <Grid lg={4}  md={6}  sx={{ pb: 4 }}>
                        <Paper
                          elevation={3}
                          sx={{
                            boxShadow: "0 0 1rem 0 rgba(0, 0, 0, .2)",
                            borderRadius: "15px",
                            backgroundColor: "#fff",

                            //  backdropFilter: "blur(5px)",
                            //  border:"1px solid #fdb714",
                            p: 2,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#002E5C",
                                fontSize: "27px",
                              }}
                            >
                              Login
                            </Typography>
                            <hr
                              style={{
                                backgroundColor: "#000",
                                border: "none",
                                height: "1.3px",
                                width: "90%",
                              }}
                            />
                          </Box>
                          <Box sx={{ mx: 2, py: 2 }}>
                            <form onSubmit={submitForm}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000",
                                  textAlign: "left",
                                }}
                              >
                                Email<span style={{ color: "red" }}>*</span>
                              </Typography>
                              <TextField
                                id="email"
                                size="small"
                                type="email"
                                name="email"
                                fullWidth
                                required
                                defaultValue={localStorage.getItem("email")}
                                sx={{
                                  mb: 2,
                                  background: "#f7f8f9",
                                  borderRadius: "4px",
                                }}
                                onChange={handleChange}
                                // value={localStorage.getItem("email")}
                              />
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000",
                                  textAlign: "left",
                                }}
                              >
                                Password <span style={{ color: "red" }}>*</span>
                              </Typography>
                              <FormControl sx={{ mb: 2 }} fullWidth>
                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                <OutlinedInput
                                  size="small"
                                  name="password"
                                  required
                                  onChange={handleChangepassword}
                                  value={password}
                                  sx={{
                                    background: "#f7f8f9",
                                    borderRadius: "4px",
                                  }}
                                  id="outlined-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Password"
                                />
                              </FormControl>
                              <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                  background: "#002E5C",
                                  color: "#fff",
                                  textTransform: "none",
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                type="submit"
                              >
                                Login
                              </Button>
                            </form>
                            <Box
                              sx={{
                                mt: 2,
                               
                              
                              }}
                            >
                              <a
                                style={{
                                  marginTop: "10px",
                                  // marginLeft: "8px",
                                  color: "#000",
                                  fontWeight: "bold",
                               paddingBottom:"10px"
                                }}
                                href="https://accredian.com/Lost-password"
                                target="blank"
                              >
                                Forgot Password
                              </a>
                              <Typography sx={{mt:2}}>Don't have account?
                              <Link
                                style={{
                                  marginTop: "7px",
                                  marginLeft: "10px",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                                to={`/?cat=${props.catValue}`}
                              >
                                Sign Up
                              </Link>
                              </Typography> 
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            {/* </Box>
          </Grid>
        </Grid> */}
      </Box>

      </Box>

      {/* mobile version */}
      <Box sx={{display:{xs:"block",lg:"none",sm:"none",md:"none"},
      //  backgroundImage: `url(${url})`,
      //  backgroundSize: "cover",
      //  backgroundAttachment:"fixed",
      //  height:"92vh"
    }}>
      <Box
     
      >
        <Grid container>
          
          <Grid item lg={9}>
            <Box sx={{ pt: 15 }}>
              <Container fixed>
                <Box>
                  <Box>
                    <Grid container spacing={9} justifyContent="center">
                      <Grid item lg={8} sx={{ pb: 4 }}>
                        <Paper
                          elevation={3}
                          sx={{
                            boxShadow: "0 0 1rem 0 rgba(0, 0, 0, .2)",
                            borderRadius: "15px",
                            backgroundColor: "#fff",

                            //  backdropFilter: "blur(5px)",
                            //  border:"1px solid #fdb714",
                            p: 2,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#002E5C",
                                fontSize: "27px",
                              }}
                            >
                              Login
                            </Typography>
                            <hr
                              style={{
                                backgroundColor: "#000",
                                border: "none",
                                height: "1.3px",
                                width: "90%",
                              }}
                            />
                          </Box>
                          <Box sx={{ mx: 2, py: 2 }}>
                            <form onSubmit={submitForm}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000",
                                  textAlign: "left",
                                }}
                              >
                                Email<span style={{ color: "red" }}>*</span>
                              </Typography>
                              <TextField
                                id="email"
                                size="small"
                                type="email"
                                name="email"
                                fullWidth
                                defaultValue={localStorage.getItem("email")}
                                required
                                sx={{
                                  mb: 2,
                                  background: "#f7f8f9",
                                  borderRadius: "4px",
                                }}
                                onChange={handleChange}
                                // value={localStorage.getItem("email")}
                              />
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000",
                                  textAlign: "left",
                                }}
                              >
                                Password <span style={{ color: "red" }}>*</span>
                              </Typography>
                              <FormControl sx={{ mb: 2 }} fullWidth>
                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                <OutlinedInput
                                  size="small"
                                  name="password"
                                  required
                                  onChange={handleChangepassword}
                                  value={password}
                                  sx={{
                                    background: "#f7f8f9",
                                    borderRadius: "4px",
                                  }}
                                  id="outlined-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Password"
                                />
                              </FormControl>
                              <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                  background: "#002E5C",
                                  color: "#fff",
                                  textTransform: "none",
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                type="submit"
                              >
                                Login
                              </Button>
                            </form>
                            <Box
                              sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <a
                                style={{
                                  marginTop: "7px",
                                  // marginLeft: "8px",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                                href="https://accredian.com/Lost-password/"
                                target="blank"
                              >
                                Forgot Password
                              </a>
                          
                              <Link
                                style={{
                                  marginTop: "7px",
                                  marginLeft: "10px",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                                to={`/?cat=${props.catValue}`}
                              >
                                Sign Up
                              </Link>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>


      </Box>
          </>
  );
};
export default LoginUpdated;
